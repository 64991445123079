

import { Component, Vue, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import {
  MENU_CUSTOM_TITLE_KEY_NAME,
  MENU_PROPERTIES_KEY_NAME
} from '@/_modules/promo-cabinet/components/cabinet-event-settings/cabinet-event-settings.vue';
import UtilsHelper from '@/_helpers/utils.helper';
import { TEventSettings } from '@/_types/event-settings.type';

@Component
export default class PageTitle extends Vue {

  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Getter('_eventStore/isEventSettingsPolled') isEventSettingsPolled: boolean;

  @Prop({type: String, default: ''})
  public readonly routeName: string;

  @Prop({type: String, default: ''})
  public readonly fallbackTitle: string;

  @Prop({type: Number, default: 25})
  public readonly maxLength: number;

  public get layout(): any {
    return (this.eventSettings && this.eventSettings.layout) || null;
  }

  public get menuProps(): any {
    return (this.layout && this.layout[MENU_PROPERTIES_KEY_NAME]) || null;
  }

  public get title(): string {
    if (!this.isEventSettingsPolled) {
      return ' ';
    }
    const pageName = UtilsHelper.routeNamesToSettingsKeys(this.routeName);
    const pageProps = (this.menuProps && this.menuProps[pageName]) || null;
    const customTitleDict = (pageProps && pageProps[MENU_CUSTOM_TITLE_KEY_NAME]) || null;
    let customTitle: string = (customTitleDict && customTitleDict[this.$i18n.locale]) || '';
    if (this.maxLength && this.maxLength < customTitle.length) {
      customTitle = customTitle.substring(0, this.maxLength) + '…';
    }
    return (customTitle || this.fallbackTitle || '').trim();
  }

}
