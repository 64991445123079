export default class ResponsivenessHelper {

  public static isNarrowScreen(): boolean {

    try {
      return window.screen.width <= 640; // TODO: use value from SCSS variable $ew-mobile-width-breakpoint
    } catch {
      // ignore
    }

    return false;

  }

  public static isScreenWidthLessOrEqualTo(value: number): boolean {

    try {
      return value ? window.screen.width <= value : false;
    } catch {
      // ignore
    }

    return false;

  }

  public static getNumberOfColumnsFittingInTheView(columnMaxWidth: number, viewWidth?: number): number {
    const viewWidthFallback = 320; // Some randomly picked value
    if (!viewWidth) {
      try {
        viewWidth = window.screen.width;
      } catch {
        viewWidth = viewWidthFallback;
      }
    }

    return Math.max(1, Math.floor(viewWidth / columnMaxWidth));
  }

  public static isWindowScrolled(): boolean {
    return window.scrollY > 0;
  }

}
