


import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';

@Component
export default class ConfirmUnsavedLeave extends Vue {
  @Getter('editFormStore/hasUnsavedChanges') hasUnsavedChanges: boolean;
  @Getter('editFormStore/isConfirmLeavePopupShown') isConfirmLeavePopupShown: boolean;

  @Action('editFormStore/showConfirmLeavePopup') showConfirmLeavePopup: () => void;
  @Action('editFormStore/hideConfirmLeavePopup') hideConfirmLeavePopup: () => void;
  @Action('editFormStore/setHasUnsavedChanges') setHasUnsavedChanges: (newVal: boolean) => void;
  @Action('editFormStore/leave') leave: () => void;

}
